import React, { Component } from 'react';
import moment from 'moment';
import 'moment/locale/nl' 
import './Home.css';
import '../../Global.css';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table';
import Misc from '../../libs/misc/Misc';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { handleGet, handlePost} from '../../ajax/AjaxRequests';

moment.locale('NL');

class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            balance : 0,
            currencyType : 'EUR',
            balances : [],
            coinPrices : [],
            previousBalances : [],
            totalEuroDeposited : 0,
            allOrders : [],
            totalWithdrawed : 0,
            updateSpeed : 2000,
            profitGraphData : [{
                name : moment(Date.parse('21 Oct 2021 00:00:00 GMT+1'),'DD/MM/YYYY').format("LL"),
                Bedrag : 0,
            }],
            trades : [],
            sellTrades : [],
            totalProfit : 0,
            totalLoss : 0,
            minimumFixedValue : 2,
            maximumFixedValue : 6,
            euroBalance : {},
        };

        this.interval = undefined;
    }

    componentDidMount() {

        this.tick(true);
        this.interval = setInterval(() => {
            this.tick(false);
        }, this.state.updateSpeed);
        
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }


    tick(fromStart) {
       this.getBalance()
        .then(coinBalances => {
            this.calculateBalancePrices(coinBalances, fromStart)
            .then(() => {
                this.calculateWithdrawal();
                this.calculateProfitAndLoss();
                this.setBuyTrades(coinBalances);
                this.setSellTrades(coinBalances);
            });
            
        })
    }

    onFirstUpdate() {
        this.getDeposits()
        .then(deposits => {
            this.updateDeposits(deposits);
        });
        
        
        
        
        
    }
    

    calculateWithdrawal() {
        this.getWithdrawalHistory()
        .then(response => {
            let withdrawals = response;
            let profitGraphData = this.state.profitGraphData;
            
            let totalWithdrawed = 0;
            for(let i = 0; i < withdrawals.length; i++) {
                let withdrawal = withdrawals[i];
                let amount = parseFloat(withdrawal.amount);
                let fee = parseFloat(withdrawal.fee);
                let total = parseFloat((amount - fee));
                totalWithdrawed += total;
            }

            this.setState({
                totalWithdrawed : totalWithdrawed,
            })
        });


    }

    getWithdrawalHistory = async() => {
        const response = await handleGet(8);
        return response.data;
    }

    updateDeposits(deposits) {
        let depositAmount = 0;

        for(let i = 0; i < deposits.length; i++) {
            let deposit = deposits[i];
            
            if(deposit.status !== 'completed' || deposit.symbol !== this.state.currencyType)
                continue;

            depositAmount += parseFloat(deposit.amount);

            this.setState({
                totalEuroDeposited : depositAmount
            })
        }
    }

    getCandleByMarketTimestamp = async(marketID, timestamp) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ marketID: marketID, timestamp : timestamp })
        };
        let orderData = await fetch('/api/candle', requestOptions);
        const body = await orderData.json();

        if(orderData.status !== 200)
            throw Error(body.message);

        
        return body;
    }

    getCoinPriceBySymbol(symbol) {
        for(let balance of this.state.balances) {
            if(balance.symbol === symbol) {

                let price = parseFloat(balance.coin_price);

                if(price > this.state.minimumFixedValue)
                    price = price.toFixed(this.state.minimumFixedValue);
                else
                    price = price.toFixed(this.state.maximumFixedValue);

                return price;
            }
        }

        return 0;
    }

    getDeposits = async() => {
        const response = await handleGet(7);
        return response.data;
    }

    getBalance = async() => {
        const response = await handleGet(3);
        return response.data;
    }

    calculateBalancePrices = async(balances, fromStart) => {
        let totalBalance = 0;
        let newBalances = [];
        let euroBalance = 0;
        for(let i = 0; i < balances.length; i++) {
            let balance = balances[i];
            totalBalance += parseFloat(balance.amount_price);

            if(balance.symbol === 'EUR')
            {
                euroBalance = balance;
                continue;
            } else
                newBalances.push(balance);
        }

        this.setState({
            balance : totalBalance,
            balances : newBalances,
            euroBalance : euroBalance,
        }, () => {
            if(fromStart) {
                this.onFirstUpdate();
            }
        })

    }

    calculateProfitAndLoss() {
        let trades = this.state.trades;
        let totalProfit = 0;
        let totalLoss = 0;


        for(let i = 0; i < trades.length; i++) {
            let trade = trades[i];
            let tradeProfit = parseFloat(trade.profitPerTrade);


            if(tradeProfit > this.state.minimumFixedValue)
                tradeProfit = tradeProfit.toFixed(this.state.minimumFixedValue);
            else
                tradeProfit = tradeProfit.toFixed(this.state.maximumFixedValue);

            if(tradeProfit > 0)
                totalProfit += parseFloat(tradeProfit);
            else if(tradeProfit < 0)
                totalLoss += Math.abs(parseFloat(tradeProfit));  

                
        }

        totalProfit = parseFloat(totalProfit);
        totalLoss = parseFloat(totalLoss);

        if(totalProfit > this.state.minimumFixedValue)
            totalProfit = totalProfit.toFixed(this.state.minimumFixedValue);
        else
            totalProfit = totalProfit.toFixed(this.state.maximumFixedValue);
        
        if(totalLoss > this.state.minimumFixedValue)
            totalLoss = totalLoss.toFixed(this.state.minimumFixedValue);
        else
            totalLoss = totalLoss.toFixed(this.state.maximumFixedValue);

        this.setState({
            totalProfit : totalProfit,
            totalLoss : totalLoss,
        });
    }

    getPreviousBalanceByCoin(coin) {
        let previousBalances = this.state.previousBalances;
        for(let i = 0; i < previousBalances.length; i++) {
            let balance = previousBalances[i];


            if(coin === balance.symbol) {    
                return balance;    
            }
                
        }

        return undefined;
    }

    setBuyTrades = async(balancesData) => {
        let balances = balancesData;
        let newTrades = [];
        let response = await handleGet(4);
        let trades = response.data;

        if(!trades)
            return;


        trades.sort(function(a, b) {
            return a.market.localeCompare(b.market) || b.timestamp - a.timestamp;
        });
        



        for(let i = 0; i < balances.length; i++) {
            let balance = balances[i];
            let balanceSymbol = balance.symbol;
            let amount = balance.amount;
            let currentAmount = 0;

            for(let x= 0; x < trades.length; x++) {
                let trade = trades[x];
                let side = trade.side;

                

               if(side === 'buy') {
                   let symbol = trade.market.split('-')[0];
                   if(balances === undefined) {
                       return;
                   }

                   
                      if(symbol === balanceSymbol && currentAmount < amount ) {
                        let newTradeObj = {
                            id : trade.id,
                            symbol : symbol,
                            price : trade.price,//trade.price > this.state.minimumFixedValue ? trade.price.toFixed(2) : trade.price,
                            create_date : moment(new Date(parseInt(trade.timestamp)), 'DD/MM/YYYY').format('LLLL'),
                            amount : trade.amount,
                            profitPerTrade : parseFloat(trade.profit_per_trade),
                            profitPercentage : parseFloat(trade.profit_percentage),
                            current_coin_price : parseFloat(trade.current_coin_price),
                            bought_price : parseFloat(trade.bought_price),
                            spentAmount : parseFloat(trade.amount * trade.bought_price),
                            fee : parseFloat(trade.fee)
                        }
                        
                        newTrades.push(newTradeObj);
                        currentAmount += parseFloat(trade.amount);
                    }
               }
            }

        }

        this.setState({
            trades : newTrades
        });
    }

    setSellTrades = async(balancesData) => {
        let balances = balancesData;
        let newTrades = [];
        let response = await handleGet(25);
        let sales = response.data

        for(let x= 0; x < sales.length; x++) {
            let trade = sales[x];
            let side = trade.side;
           if(side === 'sell') {
               let symbol = trade.market.split('-')[0];

               let newTradeObj = {
                id : trade.id,
                symbol : symbol,
                price : trade.price,
                create_date : moment(new Date(parseInt(trade.timestamp)), 'DD/MM/YYYY').format('LLLL'),
                amount : trade.amount,
                current_coin_price : parseFloat(trade.current_coin_price),
                bought_price : (parseFloat(trade.price) * parseFloat(trade.amount)),
                fee : parseFloat(trade.fee),
                feeCurrency : trade.fee_currency,
                totalPrice : parseFloat(trade.price) - parseFloat(trade.fee),
                totalReceivedPrice : parseFloat(trade.price) * parseFloat(trade.amount) - parseFloat(trade.fee)
            }
            newTrades.push(newTradeObj);
           }
        }

        this.setState({
            sellTrades : newTrades
        });
    }

    


  render() {
    
    let balances = this.state.balances;
    let data = this.state.profitGraphData;
    let trades = this.state.trades;
    let sellTrades = this.state.sellTrades;
    let totalProfit = 0;
    let totalPercentage = 0;
    let totalInvested = 0;
    for(let trade of trades) {
        totalProfit += trade.profitPerTrade;
        
        totalInvested += trade.spentAmount;
        totalInvested += trade.fee;
    }

    let difference = this.state.balance - totalInvested;
    totalPercentage = parseFloat(difference / totalInvested ) * 100;

    let totalReceivedAmount = 0;
    for(let trade of sellTrades)
        totalReceivedAmount += trade.totalReceivedPrice;

    let today = moment(new Date(), 'DD/MM/YYYY').format('LLLL');
    return (

       
        <div className="content">
            
            <Row>
                <Row className="mb-4">

                    <div className='flex-justify-content-space-between flex-direction-row'>
                        <div className="f-5 home-welcome-title">Welkom {this.props.loggedInUser !== undefined && this.props.loggedInUser.username}</div>
                        <div>{today}</div>
                    </div>
                    
                </Row>
                <Row md="auto">
                    <Col md="4" className="top-content-block content-block mr-2">
                        <Row className=" mt-4 ml-2">
                            <Col md="auto">
                                <div className="text-center text-bold color-white f-5">
                                    Portfolio
                                </div>
                            
                            </Col>
                        </Row>

                        <Row className=" mt-4 ml-2">
                            <Col md="auto">
                                <div className="text-bold color-white">
                                    Balans:
                                </div>
                                <div className={`${(this.state.balance > this.state.totalEuroDeposited) ? 'color-green' : 'color-red'} text-bold f-7`}>
                                    €{parseFloat(this.state.balance).toFixed(this.state.minimumFixedValue)}
                                </div>
                            
                            </Col>
                        </Row>
                        <Row className=" mt-4 ml-2">
                            <Col md="auto">
                                <div className="text-bold color-white">
                                    Euro tegoed:
                                </div>
                                <div className={`${(this.state.euroBalance.amount >= 0) ? 'color-green' : 'color-red'} text-bold f-7`}>
                                    €{parseFloat(this.state.euroBalance.amount).toFixed(this.state.minimumFixedValue)}
                                </div>
                            
                            </Col>
                        </Row>
                        <Row className=" mt-4 ml-2">
                            <Col md="auto">
                                <div className="text-bold color-white">
                                    Totaal geïnvesteerd:
                                </div>
                                <div className="f-7 text-bold color-white">
                                    €{parseFloat(this.state.totalEuroDeposited).toFixed(this.state.minimumFixedValue)}
                                </div>
                            
                            </Col>
                        </Row>
                        <Row className=" mt-4 ml-2">
                            <Col md="auto">
                                <div className="text-bold color-white">
                                    Totaal opgenomen bedrag:
                                </div>
                                <div className={`$color-white text-bold f-7`}>
                                    €{parseFloat(this.state.totalWithdrawed).toFixed(this.state.minimumFixedValue)}
                                </div>
                            
                            </Col>
                        </Row>
                        <Row className=" mt-4 ml-2">
                            <Col md="auto">
                                <div className="text-bold color-white">
                                    Totale huidige winst:
                                </div>
                                <div className={`${this.state.totalProfit > 0 ? 'color-green' : 'color-red'} text-bold f-7`}>
                                    €{parseFloat(this.state.totalProfit)}
                                </div>
                            
                            </Col>
                        </Row>
                        <Row className=" mt-4 ml-2">
                            <Col md="auto">
                                <div className="text-bold color-white">
                                    Totaal huidig verlies:
                                </div>
                                <div className="color-red text-bold f-7">
                                    €{parseFloat(this.state.totalLoss)}
                                </div>
                            
                            </Col>
                        </Row>
                    </Col>
                    
                </Row>
            </Row>
            <Row className="justify-content-md-center content-block mt-5">
                <Row className=" mt-4 ml-2">
                    <Col md="auto">
                        <div className="text-center text-bold color-white f-5">
                            Actieve munten
                        </div>
                    
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col className="mx-4">
                        <Table striped borderless responsive>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Munt</th>
                                    <th>Aantal</th>
                                    <th>Waarde</th>
                                    <th>Munt prijs</th>
                                </tr>
                            </thead>
                            <tbody>
                                {balances.length > 0 && balances.map((balance, key) => (
                                    <tr key={key}>
                                        <td>{(key + 1)}</td>
                                        <td><img alt="" className="mr-2 full-border-radius" src={`/img/coins/${balance.symbol}.png`} width={32} height={32} />{balance.symbol}</td>
                                        <td><div className="color-blue">{balance.amount}</div></td>
                                        <td>€{parseFloat(balance.amount_price).toFixed(this.state.minimumFixedValue)}</td>
                                        <td>
                                            <div className={`${balance.coin_price > balance.previous_price ? 'color-green' : 'color-red'}`}>€{balance.coin_price}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                
            </Row>
            <Row className="justify-content-md-center content-block mt-5">
                <Row className=" mt-4 ml-2">
                    <Col md="auto">
                        <div className="text-center text-bold color-white f-5">
                            Winst per huidige munt aankopen
                        </div>
                    
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col className="mx-4">
                       <Table striped borderless responsive size="" className="color-white">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Munt</th>
                                    <th>Aantal</th>
                                    <th>Uitbesteed bedrag</th>
                                    <th>Aankoop munt-bedrag</th>
                                    <th>Transactie kosten</th>
                                    <th>Huidige munt waarde</th>
                                    <th>Winst Bedrag</th>
                                    <th>Winst Percentage</th>
                                    <th>Aankoop datum</th>
                                </tr>
                            </thead>
                            <tbody>
                                {trades.length > 0 && trades.map((trade, key) => (
                                    <tr key={key}>
                                        <td className='current-profit-row'>{(key + 1)}</td>
                                        <td className='current-profit-row'><img alt="" className="mr-2 full-border-radius" src={`/img/coins/${trade.symbol}.png`} width={32} height={32}/>{trade.symbol}</td>
                                        <td className='current-profit-row'><div className="color-blue">{trade.amount}</div></td>
                                        <td className='current-profit-row'>€ {parseFloat(trade.spentAmount).toFixed(this.state.minimumFixedValue)}</td>
                                        <td className='current-profit-row'>€ {parseFloat(trade.price)}</td>
                                        <td className='current-profit-row'>€ {parseFloat(trade.fee).toFixed(this.state.minimumFixedValue)}</td>
                                        <td className='current-profit-row'>€ {parseFloat(this.getCoinPriceBySymbol(trade.symbol))}</td>
                                        <td className='current-profit-row'>
                                            <div className={`${trade.profitPerTrade > 0 ? 'color-green' : 'color-red'}`}>€ {(!isNaN(trade.profitPerTrade) ? parseFloat(trade.profitPerTrade > this.state.minimumFixedValue ? trade.profitPerTrade.toFixed(this.state.minimumFixedValue) : trade.profitPerTrade.toFixed(this.state.maximumFixedValue)) : 0)}</div>
                                        </td>
                                        <td className='current-profit-row'>
                                            <div className={`${trade.profitPercentage > 0 ? 'color-green' : 'color-red'}`}>{(!isNaN(trade.profitPercentage) ? parseFloat(trade.profitPercentage).toFixed(this.state.minimumFixedValue) : 0)}%</div>
                                        </td>
                                        <td className='current-profit-row'>
                                            {trade.create_date}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row className="py-0 my-0">
                    <Col className="mx-4">
                       <Table striped borderless responsive size="" className="color-white">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                    <tr>
                                        <td>Totaal:</td>
                                        <td className='current-profit-row'></td>
                                        <td className='current-profit-row'></td>
                                        <td className='current-profit-row'><div className={`${totalInvested > 0 ? 'color-green' : 'color-red'}`}>€ {totalInvested.toFixed(this.state.minimumFixedValue)}</div></td>
                                        <td className='current-profit-row'></td>
                                        <td className='current-profit-row'></td>
                                        <td className='current-profit-row'></td>
                                        <td className='current-profit-row'><div className={`${totalProfit > 0 ? 'color-green' : 'color-red'}`}>€ {totalProfit.toFixed(totalProfit > this.state.minimumFixedValue ? this.state.minimumFixedValue : this.state.maximumFixedValue)}</div></td>
                                        <td className='current-profit-row'><div className={`${totalProfit > 0 ? 'color-green' : 'color-red'}`}>{totalPercentage.toFixed(totalPercentage > this.state.minimumFixedValue ? this.state.minimumFixedValue : this.state.maximumFixedValue)}%</div></td>
                                        <td className='current-profit-row'></td>
                                    </tr>
                                
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Row>
            <Row className="justify-content-md-center content-block mt-5">
                <Row className=" mt-4 ml-2">
                    <Col md="auto">
                        <div className="text-center text-bold color-white f-5">
                            Verkochtte munten
                        </div>
                    
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col className="mx-4">
                       <Table striped borderless responsive size="" className="color-white">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Munt</th>
                                    <th>Aantal</th>
                                    <th>Ontvangen bedrag per munt</th>
                                    <th>Totaal ontvangen</th>
                                    <th>Verkoop munt-bedrag</th>
                                    <th>Verkoop datum</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sellTrades.length > 0 && sellTrades.map((trade, key) => (
                                    <tr key={key}>
                                        <td>{(key + 1)}</td>
                                        <td><img alt="" className="mr-2 full-border-radius" src={`/img/coins/${trade.symbol}.png`} width={32} height={32} />{trade.symbol}</td>
                                        <td><div className="color-blue">{trade.amount}</div></td>
                                        <td>€ {parseFloat(trade.totalPrice).toFixed(this.state.maximumFixedValue)}</td>
                                        <td><div className='color-green'>€ {parseFloat(trade.totalReceivedPrice).toFixed(this.state.maximumFixedValue)}</div></td>
                                        <td>€ {parseFloat(trade.price).toFixed(this.state.maximumFixedValue)}</td>
                                        <td>
                                            {trade.create_date}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row className="py-0 my-0">
                    <Col className="mx-4">
                       <Table striped borderless responsive size="" className="color-white">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className='flex-justify-content-space- w-100 test'>
                                    <td>Totaal:</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><div className={`${totalReceivedAmount > 0 ? 'color-green' : 'color-red'}`}>€ {totalReceivedAmount.toFixed(this.state.maximumFixedValue)}</div></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Row>
        </div>
    );
  }
}

export default Home;