import React, { Component } from 'react';
import './Header.css';
import '../../Global.css';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav';
import Config from '../../config/Config';

export default class Header extends Component {
    
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
    }


  render() {
    return (
        <div className="header">
                <Row className="px-5">
                    <Col xs={2}>
                        <a href="/">
                            <div className="title title-color">
                                {Config.title}              
                            </div>    
                        </a> 
                    </Col>
                    <Col xs={10}>

                    {(this.props.loggedInUser !== undefined &&
                        <Nav onSelect={(selectedKey) => {this.props.onNavigationButtonClick(selectedKey)}}>
                            <NavigationItem eventKey={'home'} title={'Home'} activePage={this.props.activePage}/>
                            <NavigationItem eventKey={'automate'} title={'Automatiseren'} activePage={this.props.activePage}/>
                        </Nav>
                    )}
                    </Col>
                </Row>
        </div>
    );
  }
}



class NavigationItem extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
    }


    render() {
        return (
            <Nav.Item as="li">
                <Nav.Link className={((this.props.activePage === this.props.eventKey) ? 'title-color' : 'color-white')}  eventKey={this.props.eventKey}>{this.props.title}</Nav.Link>
            </Nav.Item>
        );

    }
}