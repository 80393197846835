import React, { Component } from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Dashboard from "./components/dashboard/Dashboard";
import Config from './config/Config';
//https://github.com/bitvavo/node-bitvavo-api

class App extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
    document.title = Config.title;
  };



  render() {
    return (
      <div className="body">
        {
          <Dashboard/>
        }
      </div>      
    );
  }
}

export default App;


/*
        // let profit = 0;
        // let allOrders = this.state.allOrders;
        // let totalBuys = 0;
        // let totalSells = 0;

        // for(let i = 0; i < allOrders.length; i++) {
        //     let ordersBySymbol = allOrders[i];
            

        //     for(let x = 0; x < ordersBySymbol.length; x++) {
        //         let order = ordersBySymbol[x];
        //         let buyOrSell = order.side;
        //         let fee = parseFloat(order.feePaid);
        //         let totalValue = parseFloat(order.filledAmountQuote) - fee;
        //         let status = order.status;

        //         if(status !== 'filled')
        //             continue;

        //         if(buyOrSell === 'buy') {    
        //             totalBuys += totalValue;
        //         } else {
        //             totalSells += totalValue;
        //         }
        //     }
        // }

        // profit = totalSells - this.state.totalEuroDeposited;
        
        // this.setState({
        //     totalProfit : profit,
        // });

        //*/