import React, { Component } from 'react';
import './Dashboard.css';
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Container from 'react-bootstrap/Container'
import Home from '../../components/home/Home';
import Automate from '../../components/automate/Automate';
import Login from '../../components/login/Login';

class Dashboard extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
          activePage : 'home',
          loggedIn : false,
          loggedInUser : undefined,
        };
    }

    componentDidMount() {

    }

    onNavigationButtonClick(item) {
      this.setState({
        activePage : item
      });
    }

    onLoginSuccesfull(succesfull, user) {
      this.setState({
        loggedIn : succesfull,
        loggedInUser : user,
      });
    }

    onLogoutButtonClick() {
        this.setState({
          loggedInUser : undefined
        })
    }



  render() {
    return (
        <div>
          <Header loggedInUser={this.state.loggedInUser} onNavigationButtonClick={this.onNavigationButtonClick.bind(this)} key={this.state.activePage} activePage={this.state.activePage} />
            <Container>
              {
                (this.state.loggedIn ? 
                  <div>
                    {this.state.activePage === 'home' && <Home loggedInUser={this.state.loggedInUser}/>}
                    {this.state.activePage === 'automate' && <Automate loggedInUser={this.state.loggedInUser}/>}
                  </div>
                  :
                  <Login onLoginSuccessfull={this.onLoginSuccesfull.bind(this)}/>
                )
              }              
            </Container>
          <Footer/>
        </div>
    );
  }
}

export default Dashboard;