var Config = {};

Config.title = 'EE Cooperation';
Config.apiUrl = 'https://evannagel.nl/';
// Config.apiUrl = 'http://localhost/ee-crypt/';
Config.scriptUrl = 'scripts/script.php?script_id=';
Config.loginUrl = 'scripts/login.php';
Config.pages = [
    {title : 'Home', value : 'home'},
];
Config.pincodeAmount = 5;
Config.backgroundColor = '#0d111f';
Config.backgroundColorDarker = '#080a13';








module.exports = Config;