import React, { Component } from 'react';
import './Footer.css';
class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {

    }


  render() {
    return (
        <div className="footer mt-5 text-center">
            Copyrighted by © Evan Nagel
        </div>
    );
  }
}

export default Footer;