import React, { Component } from 'react';
import './Login.css';
import '../../Global.css';
import Row from 'react-bootstrap/Row'
import Config from '../../config/Config';
import { login } from '../../ajax/AjaxRequests';


class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
          username : '',
          pincodes : [],
          errorMessage : ''
        };

        let pincodeAmount = Config.pincodeAmount;
        for(let i = 0; i < pincodeAmount; i++) {
          this.state.pincodes[i] = '';
        }
        this.inputFields = [];
    }

    componentDidMount() {
      this.inputFields[0].focus();
    }


    handleChange(event, index) {
        let value = event.target.value;

        if(value === undefined || value === '')
          return;
        
        let pincodes = this.state.pincodes;
        let pincodeAmount = Config.pincodeAmount;

        pincodes[index] = value;


        this.setState({
          pincodes : pincodes
        });

        if(index < (pincodeAmount - 1)) {
          if(this.checkPincode()) {
            this.login();
          } else {
            this.inputFields[index + 1].focus();
          }

        } else {

          // console.log('trying to login with', pincodes, value);
          if(this.checkPincode())
            this.login();
        }
    }

    checkPincode() {
      for(let i = 0; i < this.state.pincodes.length; i++) {
        let code = this.state.pincodes[i];

        if(code === undefined || code === '')
          return false;

      }

      return true;
    }

    async login() {

      let fullPincode = '';
      let pincodes = this.state.pincodes;

      for(let i = 0; i < pincodes.length; i++) {
        let pincode = pincodes[i];
        fullPincode = fullPincode.concat(pincode);
      }

      fullPincode = parseInt(fullPincode);  
      
      await this.setState({
        errorMessage : ''
      });
      
        let response = await login(fullPincode);
        let succesfullLogin = response['status'];
        let error = response['error'];

        if(succesfullLogin === true) {
          let user = response.user;
          this.props.onLoginSuccessfull(succesfullLogin, user);
        } else {
          this.onWrongPincode(error);
        }
      
      
    }

    onWrongPincode(error) {

      let pincodes = this.state.pincodes;

      for(let i = 0; i < pincodes.length; i++) {
        pincodes[i] = '';
        this.inputFields[i].value = '';
      }
      
      this.setState({
        errorMessage : error,
        pincodes : pincodes,
      })

      this.inputFields[0].focus();
    }


  render() {
    let pincodeAmount = Config.pincodeAmount;

    return (
        <Row className="justify-content-center align-content-center login-content" md="auto">
          <form>
              
              <Row>
              {(this.errorMessage !== '' && 
                <div className="color-red">{this.state.errorMessage}</div>  
              )}
                <div>Vul hier uw pincode in.</div>
                  <label>
                    
                  {[...Array(pincodeAmount)].map((number, index) =>
                    <input key={index} ref={(input) => { this.inputFields[index] = input; }} type="password" className="pincode-input background-color-darker color-white text-center mr-1" value={this.state.value} onChange={event => this.handleChange(event, index)} />
                  )}

                </label>
              </Row>
              
          </form>
        </Row>
    );
  }
}

export default Login;