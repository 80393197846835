var Config = require('../config/Config');


export const handlePost = async function(scriptID, data){
    
        let fd = new FormData();

        for(let i = 0; i < data.length; i++) {
            let item = data[i];
            fd.append(item.key, item.value);
        }
        
        const endpoint = Config.apiUrl + Config.scriptUrl + scriptID;

        let response = await fetch(endpoint, {
            method: 'POST',
            body: fd,
            credentials:"include",
            xhrFields: { withCredentials: true }
        });
        
        response = await response.json();
        return response;
    
  };

  export const handleGet = async function(scriptID) {
        const endpoint = Config.apiUrl + Config.scriptUrl + scriptID;        
         let response = await fetch(endpoint, {
            method: 'GET',
            credentials:"include",
            xhrFields: { withCredentials: true }
        });

        response = await response.json();


        return response;
        

    };

  export const login = async function(pincode){
    
        let fd = new FormData();
        fd.append("pincode", pincode)


        const endpoint = Config.apiUrl + Config.loginUrl;

        let response = await fetch(endpoint, {
            method: 'POST',
            body: fd,
            credentials:"include",
            xhrFields: { withCredentials: true }
        });

        

        response = await response.json();
        return response;
    
  };

