import React, { Component } from 'react';
import './Automate.css';
import '../../Global.css';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import moment from 'moment';
import { handleGet, handlePost} from '../../ajax/AjaxRequests';

class Automate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            automatedSellMargin : 5,
            editingProfitMargin : false,
            updateSpeed : 2000,
            automatedPurchases : [],
            automatedSales : [],
            trades : [],
            balances : [],
            expectedOrders : [],
            sellMarginValueOnly : 5,
        };

        this.interval = undefined;
        moment.locale();
    }

    componentDidMount() {
        this.tick(true);
        this.interval = setInterval(() => {
            this.tick(false);
        }, this.state.updateSpeed);
        
        this.getAutomatedProfitMargin();
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    tick(fromStart) {
        this.setAutomatedPurchases();
        this.setAutomatedSales();
        this.setTrades();
        this.setExpectedOrders();
    }

    setBalance = async() => {
        const response = await handleGet(3);
        let balances = response.data;
        let newBalances = [];

        for(let i = 0; i < balances.length; i++) {
            let balance = balances[i];
            
            if(balance.symbol === 'EUR')
            {
                continue;
            } else
                newBalances.push(balance);
        }

        this.setState({
            balances : newBalances,
        });
        

        return newBalances;
    }

    editProfitMargin() {
        this.setState({
            editingProfitMargin : true
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        this.updateAutomatedSellMargin(this.state.sellMarginValueOnly);

        this.setState({
            editingProfitMargin : false,
        });
    }

    handleChange(event) {
        this.setState({
            sellMarginValueOnly : event.target.value
        });
    }

    getAutomatedProfitMargin = async() => {
        const response = await handleGet(9);

        let data = response.data;
        let sellMargin = data[0].sell_margin;

        this.setState({
            automatedSellMargin : sellMargin,
        });
    }

    setAutomatedPurchases = async() => {
        const response = await handleGet(5);

        let purchases = response.purchases;

        this.setState({
            automatedPurchases : purchases
        });

    }

    setAutomatedSales = async() => {
        const response = await handleGet(10);
        let data = response.data;

        this.setState({
            automatedSales : data
        });

    }

    updateAutomatedSellMargin = async(automatedSellMargin) => {
        let response = await handlePost(11, [{key: 'sell_margin', value : automatedSellMargin}]);

        if(response['status'] === true) {
            this.setState({
                automatedSellMargin : automatedSellMargin,
            })
        }
    }

    setTrades = async() => {
        let balancesData = await this.setBalance();
        let balances = balancesData;

        const response = await handleGet(4);
        let trades = response.data;
        let newTrades = [];

       trades.sort(function(a, b) {
            return b.timestamp - a.timestamp;
        });



        for(let i = 0; i < balances.length; i++) {
            let balance = balances[i];
            let balanceSymbol = balance.symbol;
            let amount = balance.amount;
            let currentAmount = 0;

            for(let x= 0; x < trades.length; x++) {
                let trade = trades[x];
                let side = trade.side;

                

               if(side === 'buy') {
                   let symbol = trade.market.split('-')[0];

                   if(balances === undefined) {
                       return;
                   }
                      if(symbol === balanceSymbol && currentAmount < amount) {
                        let newTradeObj = {
                            id : trade.id,
                            symbol : symbol,
                            price : trade.price,
                            create_date : moment(new Date(parseInt(trade.timestamp)),'DD/MM/YYYY').format('LLLL'),
                            amount : trade.amount,
                            profitPerTrade : parseFloat(trade.profit_per_trade),
                            profitPercentage : parseFloat(trade.profit_percentage),
                        }

                        newTrades.push(newTradeObj);
                        currentAmount += trade.amount;
                    }
               }
            }

        }

        newTrades.sort(function(a, b) {
            var textA = a.symbol.toUpperCase();
            var textB = b.symbol.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });

        this.setState({
            trades : newTrades
        });
    }

    setExpectedOrders = async() => {
        const response = await handleGet(5);

        let expectedOrders = response.data;

        this.setState({
            expectedOrders : expectedOrders
        })
    }



  render() {

    let sales = this.state.automatedSales;
    let trades = this.state.trades;
    let balances = this.state.balances;
    let expectedOrders = this.state.expectedOrders;
    if(balances.length == 0) {
        return  <div className="home-loading-block">
                    <div>
                        Laden...
                    </div>
                    
                </div>
    }
    
    return (
        <div className="content">
            <Row className="flex-justify-content-center">
                <Col md="6" className="top-content-block content-block automate-content-block">
                        <Row className=" my-4 ml-2">
                            <Col>
                                <div className="text-bold color-white f-5">
                                    Automatiseren
                                </div>
                                <Row className="mb-5"/>
                                <Row>
                                   <div className="text-bold color-white">
                                        Verkoop marge:
                                    </div>
                                    {
                                        (!this.state.editingProfitMargin ? 
                                        <div className={`${this.state.automatedSellMargin > 0 ? 'color-green' : 'color-red'} text-bold f-7 flex-direction-row flex-align-items-center`}>
                                            {this.state.automatedSellMargin}%
                                            <div className="automate-edit-margin f-2 ml-2">
                                                <Button onClick={this.editProfitMargin.bind(this)} variant="info" size="sm">Aanpassen</Button>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <form onSubmit={this.handleSubmit.bind(this)}>
                                                <label>
                                                    <input type="text" value={this.state.value} onChange={this.handleChange.bind(this)} />
                                                </label>
                                                <input type="submit" value="Bevestig" className="background-color-blue ml-2" />
                                            </form>
                                        </div>
                                    )
                                    }
                                </Row>
                            
                            </Col>
                        </Row>
                        
                    </Col>
            </Row>
            <Row className="justify-content-md-center content-block mt-5">
                <Row className=" mt-4 ml-2">
                    <Col md="auto">
                        <div className="text-left center text-bold color-white f-5">
                            Afgeronde verkoop orders <div className="f-1">(In testfase)</div>
                        </div>
                    
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col className="mx-4">
                        <Table striped borderless responsive size="" className="color-white">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Munt</th>
                                    <th>Aantal</th>
                                    <th>Verkoop prijs</th>
                                    <th>Datum</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sales.length > 0 && sales.map((sale) => (
                                    <tr key={sale.id}>
                                        <td>{sale.id}</td>
                                        <td><img alt="" className="mr-2 full-border-radius" src={`/img/coins/${sale.symbol}.png`} width={32} height={32} />{sale.symbol}</td>
                                        <td>{sale.amount}</td>
                                        <td><div className="color-green">€{sale.sell_price}</div></td>
                                        <td>{moment(new Date(parseInt(sale.create_date)), 'DD/MM/YYYY').format('LLLL')}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                
            </Row>
            <Row className="justify-content-md-center content-block mt-5">
                <Row className=" mt-4 ml-2">
                    <Col md="auto">
                        <div className="text-center text-bold color-white f-5">
                            Verwachtte verkoop orders
                        </div>
                    
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col className="mx-4">
                        <Table striped borderless responsive size="" className="color-white">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Munt</th>
                                    <th>Aantal</th>
                                    <th>Aankoop prijs</th>
                                    <th>Aankoop datum</th>
                                    <th>Huidige prijs</th>
                                    <th>Te verkopen prijs</th>
                                    <th>Te verwachtte winstmarge</th>
                                </tr>
                            </thead>
                            <tbody>
                                {expectedOrders.length > 0 && expectedOrders.map((order, key) => (
                                    <tr key={key}>
                                        <td>{(key + 1)}</td>
                                        <td><img alt="" className="mr-2 full-border-radius" src={`/img/coins/${order.symbol}.png`} width={32} height={32}/>{order.symbol}</td>
                                        <td><div className="color-blue">{order.amount}</div></td>
                                        <td>€{parseFloat(order.bought_price).toFixed((order.bought_price < 1 ? 5 : 0))}</td>
                                        <td>{moment(new Date(parseInt(order.bought_date)),'DD/MM/YYYY').format('LLLL')}</td>
                                        <td><div className="color-red">€{parseFloat(order.current_coin_price).toFixed(0)}</div></td>
                                        <td><div className="color-green">€{parseFloat(order.amount_for_profit).toFixed((order.amount_for_profit < 1 ? 5 : 0))}</div></td>
                                        <td><div className="color-green">{this.state.automatedSellMargin}%</div></td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                
            </Row>
            <Row className="justify-content-md-center content-block mt-5">
                <Row className=" mt-4 ml-2">
                    <Col md="auto">
                        <div className="text-center text-bold color-white f-5">
                            Actieve munten
                        </div>
                    
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col className="mx-4">
                        <Table striped borderless responsive size="" className="color-white">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Munt</th>
                                    <th>Aantal</th>
                                    <th>Waarde</th>
                                    <th>Munt prijs</th>
                                </tr>
                            </thead>
                            <tbody>
                                {balances.length > 0 && balances.map((balance, key) => (
                                    <tr key={key}>
                                        <td>{(key + 1)}</td>
                                        <td><img alt="" className="mr-2 full-border-radius" src={`/img/coins/${balance.symbol}.png`} width={32} height={32}/>{balance.symbol}</td>
                                        <td><div className="color-blue">{balance.amount}</div></td>
                                        <td>€{parseFloat(balance.amount_price).toFixed(2)}</td>
                                        <td>
                                            <div className={`${balance.coin_price > balance.previous_price ? 'color-green' : 'color-red'}`}>€{balance.coin_price}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                
            </Row>
        </div>
    );
  }
}

export default Automate;



/*

            */